import React, { useState } from 'react';
import RadioButton from './RadioComponent/index';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';
import backButton from "../../../assets/backButton.svg"


type Props = {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
};

function SecPageCompoTwo({ setopenComponent, setGoal }: Props) {
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [errorbox, setErrorbox] = useState('');
  console.log('*********', check, '********');
  // **********
  // const checkValidity = (check: Boolean) => {
  //   // check ? setopenComponent(3) : setErrorbox('Select answer first');
  //   check ? setopenComponent(3) : setErrorbox('Select answer first');
  // };
  // **********
  let path = 3;
  return (
    <>
      <div onClick={() => { setopenComponent(1) }}>
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <div className="pt-20">
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-raleway  text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black	">
            We've saved clients over
            8,000,000 and counting.
          </p>
        </motion.div>
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-sans font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-[32px] text-center text-[#737373] mt-10">
            What is your goal?
          </p>
        </motion.div>
      </div>

      <div className="flex flex-col justify-center items-center mt-10">
        <RadioButton
          path={path}
          setopenComponent={setopenComponent}
          setCheck={setCheck}
          setCheck2={setCheck2}
          setCheck3={setCheck3}
          setCheck4={setCheck4}
          setGoal={setGoal}
          check={check}
          id={1}
          content={'Lower my monthly payments'}
        />
        <RadioButton
          path={path}
          setopenComponent={setopenComponent}

          setCheck={setCheck}
          setCheck2={setCheck2}
          setCheck3={setCheck3}
          setCheck4={setCheck4}
          setGoal={setGoal}
          check={check2}
          id={2}
          content={'Use the equity in my home for cash on hand'}
        />
        <RadioButton
          path={path}
          setopenComponent={setopenComponent}

          setCheck={setCheck}
          setCheck2={setCheck2}
          setCheck3={setCheck3}
          setCheck4={setCheck4}
          setGoal={setGoal}
          check={check3}
          id={3}
          content={'Use the equity in my home to pay off debt'}
        />
        <RadioButton
          path={path}
          setopenComponent={setopenComponent}

          setCheck={setCheck}
          setCheck2={setCheck2}
          setCheck3={setCheck3}
          setCheck4={setCheck4}
          setGoal={setGoal}
          check={check4}
          id={4}
          content={'Just get an idea of how I can save'}
        />
        <div className="mt-5 mb-20 flex flex-col">
          {/* <button
            // onClick={() =>
            //   // checkValidity(check ? true : check2 ? true : check3 ? true : check4 ? true : false)
            // }
            // // onClick={() => setopenComponent(3)}
            className="bg-brand rounded-full font-sans text-lg	font-bold text-white px-12  py-3 border-brand border-2  sm:px-32 sm:py-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2"
          >
            Next
          </button> */}
          <span className="text-red-700 mx-auto text-center sm:w-[65%] my-4 text-[10px] sm:text-[12px] font-semibold">
            {errorbox}
          </span>
        </div>
        {/* <Button content={"Next"}  setopenComponent = {()=> setopenComponent(3)}/> */}
      </div>
    </>
  );
}

export default SecPageCompoTwo;
