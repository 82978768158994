import React, { useState } from "react"; import { motion } from 'framer-motion';
import { textanimation } from "./Animation/index";
import backButton from "../../../assets/backButton.svg"



type Props = {
	setopenComponent: React.Dispatch<React.SetStateAction<number>>;
	setMortageBal: React.Dispatch<string>
	mortageBal: string
};

const SecPageCompoFour = ({ setopenComponent, setMortageBal, mortageBal }: Props) => {


	const [mortageBalance, setMortageBalance] = useState("")
	const [errVal, setErrVal] = useState("")
	return (

		<>
			<div onClick={() => { setopenComponent(3) }}>
				<img src={backButton} alt="" className="w-10 cursor-pointer" />
			</div>
			<div className="pt-20">
				<motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
					<p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black  ">
						See real mortgage solutions
						before you commit.
					</p>
				</motion.div>
				<motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
					<p className="font-sans font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl text-center text-[#737373] mt-10">What is your mortgage balance?</p>
				</motion.div>
			</div>
			<div className="flex flex-col justify-center items-center mt-10">
				<div className="flex flex-row bg-white rounded-full  border-2 justify-center items-center ">
					<div className=" bg-brand ml rounded-full font-sans text-lg text-white px-8 py-2 sm:px-16  ">$</div>
					<input className="outline-none rounded-full font-sans text-lg pl-4 sm:px-16 py-2 " type="text" placeholder="e.g-250,000"
						value={mortageBal}
						onChange={(e) => {
							// setMortageBalance(e.target.value)
							setMortageBal(e.target.value)
							setErrVal("")
						}} />
				</div>
				<span className="text-red-700 text-center sm:w-[65%] mx-auto my-4 text-[10px] sm:text-[12px] font-semibold">{errVal}</span>
				<div className="mt-5 mb-20">
					<button
					id="btn_next1"
						// onClick={() => setopenComponent(5)}
						onClick={() => {
							!mortageBal ? setErrVal("Field is required") : setopenComponent(5);
							document.getElementById("view")?.scrollIntoView()
						}}
						className="bg-brand select-none rounded-full font-sans text-lg	font-bold text-white px-12  py-3  sm:px-32 sm:py-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2">Next</button>
				</div>
			</div>
		</>

	);
};

export default SecPageCompoFour;
